// src/components/Contact.js
import React from 'react';
import './contact.css';
import bandImage1 from './bandImage.jpg';
import bandImage2 from './bandPav1.jpg';
import bandImage3 from './conalPav2.jpg';
import bandImage4 from './steviePav.jpg';
import bandImage5 from './nickyPav.jpg';
import bandImage6 from './dylanPav.jpg';


const Contact = () => {
    return (
        <div className="contact-container">
            <h1>Contact Us</h1>
            <div className="contact-content">
                <div className="contact-info">
                    <p>Get in touch with Black Dog Moon for any inquiries, bookings, or just to say hello!</p>
                    <ul className="social-links">
                        <li><a href="https://www.facebook.com/profile.php?id=100090149589998" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook"></i> Facebook</a></li>
                        <li><a href="https://www.instagram.com/blackdogmoon5/?__d=1%2F" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i> Instagram</a></li>
                        <li><a href="https://twitter.com/blackdogmoon" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i> Twitter</a></li>
                        <li><a href="https://www.youtube.com/@blackdogmoon-6087" target="_blank" rel="noopener noreferrer"><i className="fab fa-youtube"></i> YouTube</a></li>
                        <li><a href="https://conalmontgomery.bandcamp.com/" target="_blank" rel="noopener noreferrer"><i className="fab fa-bandcamp"></i> Bandcamp</a></li>
                    </ul>
                    <p className="email-contact">Email: <a href="mailto:conaleaf@outlook.com">conaleaf@outlook.com</a></p>
                </div>
                <div className="contact-images">
                    <img src={bandImage1} alt="Black Dog Moon performing" />
                    <img src={bandImage2} alt="Black Dog Moon backstage" />
                    <img src={bandImage3} alt="Black Dog Moon in studio" />
                    <img src={bandImage4} alt="Black Dog Moon in studio" />
                    <img src={bandImage5} alt="Black Dog Moon in studio" />
                    <img src={bandImage6} alt="Black Dog Moon in studio" />
                </div>
            </div>
        </div>
    );
};

export default Contact;