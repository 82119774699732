// src/components/Home.js
import React from 'react';
import './home.css';
import bandImage from './bandImage.jpg'; // Add an appropriate image of the band

const Home = () => {
    return (
        <div className="home-container">
            <div className="hero-section">
                <img src={bandImage} alt="Black Dog Moon" className="hero-image" />
                <div className="hero-text">
                    <h1>Black Dog Moon</h1>
                    <p>Irish rockers Black Dog Moon just released their debut album Black Dog Moon on June 28, 2024 to critical acclaim. They have been making some ripples in their own homeland and rock fans are starting to take notice. The Dogs are ready, are you? Unleash the dogs.</p>
                </div>
            </div>
            <div className="content-section">
                <h2>Latest News</h2>
                <p>Stay tuned for the latest updates on our music, tours, and more. </p>
                <h2>Upcoming Events</h2>
                <ul>
                    <li>March 15th, 2025 - Bangor -  Imperial Bar</li>
                    <li>Stay posted for more gigs into 2025 and beyond!</li>
                </ul>
            </div>
        </div>
    );
};

export default Home;

