// src/components/About.js
import React from 'react';
import './about.css';
import bandPhoto from './bandPhoto.jpeg'; // Add an appropriate photo of the band

const About = () => {
    return (
        <div className="about-container">
            <h1>About Black Dog Moon</h1>
            <div className="about-content">
                <img src={bandPhoto} alt="Black Dog Moon" className="about-photo" />
                <div className="about-text">
                    <p>Black Dog Moon is a rock band from Ireland, known for their powerful performances and unique sound. Formed in 2020, the band has quickly made a name for itself in the local music scene.</p>
                    <p>The band consists of five talented musicians:</p>
                    <ul>
                        <li><strong>Conal Montgomery</strong> - Vocals</li>
                        <li><strong>Nicky Brown</strong> - Bass/Vocals</li>
                        <li><strong>Stevie Glackin</strong> - Drums</li>
                        <li><strong>Dylan Kelly</strong> - Guitar/Vocals</li>
                        <li><strong>Daniel Martin</strong> - Guitar</li>
                    </ul>
                    <p>Their debut album, <em>Black Dog Moon</em>, released on June 28, 2024, is a highly anticipated event for rock fans. With their distinctive sound and energetic performances, Black Dog Moon is set to take the music world by storm.</p>
                    <p>The band draws inspiration from classic rock legends and combines it with a modern twist to create their unique sound. Their journey has just begun, and they are ready to share their music with the world.</p>
                </div>
            </div>
            <div className="spotify-embed">
            <iframe style={{ borderRadius: '12px' }} src="https://open.spotify.com/embed/artist/1sZjGqGKcNcfmoHryaLDW5?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
            </div>
        </div>
    );
};

export default About;
