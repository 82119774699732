import React from 'react';
import './cornerstone.css';
import festivalPoster from './cornerstone.jpg';

const CornerstoneReview = () => {
    const images = [
        '0eaa16e5-e7b0-4194-8d49-cbe20783ec8f.JPG',
        '0ec14a58-d387-42ae-88cb-eb55980e6287.JPG',
        '2ffba213-b44c-4f1a-a2ec-c15aaf45e1cd.JPG',
        '4fd1dd6b-9207-4b3e-a239-b6c1ab12b959.JPG',
        '6ae2dfea-e77f-4deb-ac03-00d52a50b7b1.JPG',
        '9dc6df1b-63e9-4c22-82eb-2310b9a7ee37.JPG',
        '10d0cfef-8a6f-44f1-b806-e1c9cc83174c.JPG',
        '32c77414-c8ff-4c81-b5a8-1e67a9a10963.JPG',
        '160a47a4-1372-45ee-9cc6-ac94ee5f6e1e.JPG',
        '6585c784-31fc-4db4-b2b6-a10d561060b5.JPG',
        '17270855-157b-45b3-998d-a16e0c519b83.JPG',
        'c442e8ad-8aff-4093-9d6b-c070eb7372c0.JPG',
        'c2369dd3-0aea-4e15-a072-df31afdf40a6.JPG',
        'd6f94dab-a914-42ab-9173-2e299e3fcaa2.JPG',
        'df794ca2-080e-4f54-987a-6ec9556d1519.JPG',
        'e87cc62b-f8d9-43ca-b278-832542248e76.JPG'
    ];

    return (
        <div className="cornerstone-container">
            <h1>Cornerstone Festival 2024 Review</h1>
            
            <div className="festival-content">
                <div className="poster-container">
                    <img src={festivalPoster} alt="Cornerstone Festival Poster" className="festival-poster" />
                </div>
                
                <div className="festival-info">
                    <h2>Sunshine, Sunflowers and Song</h2>
                    <p>
                        On Saturday 10th August, the 3rd annual Corner Stone Fest took place in the beautiful surroundings of Sunflower Cottage, Annacloy, County Down. 
                        The event was a resounding success, showcasing local talent and raising funds for a worthy cause.
                    </p>

                    <h2>Event Highlights</h2>
                    <ul>
                        <li>Ten musical acts performed from 12 noon to 9pm</li>
                        <li>Beautiful weather and blooming sunflowers set the scene</li>
                        <li>Family-oriented atmosphere with a peaceful ambiance</li>
                        <li>All performers donated their time for free</li>
                    </ul>

                    <h2>Fundraising Success</h2>
                    <p>
                        The Corner Stone Fest raised an impressive <strong>£5,065</strong> for LCCL Charity based in Downpatrick. 
                        This local charity provides vital services to help people overcome mental health issues, social isolation, and addiction.
                    </p>

                    <h2>Organizer's Vision</h2>
                    <p>
                        Conal Montgomery, the event organizer, expressed his amazement at the turnout and the musicians' performances. 
                        He aims to grow the festival year by year, increasing awareness and support for LCCL Charity.
                    </p>

                    <h2>Special Mentions</h2>
                    <ul>
                        <li>Robin Elliott, TV and radio broadcaster, attended to show support</li>
                        <li>Duxy Leahy, local Killyleagh artist, donated unique lighthouse paintings for a future raffle</li>
                        <li>Eva Rowan shared insights about Sunflower Cottage and its events supporting various charities</li>
                    </ul>

                    <h2>Musical Lineup</h2>
                    <p>
                        The festival featured performances by Conal Montgomery &amp; Co., First Ted on the Moon, Every March, Short Circuit, 
                        Rikki Jordan band, Take it Easy, Charlie Hanlon, The Thing is, Zac Mac, and Black Dog Moon.
                    </p>

                    <h2>Podcast Feature</h2>
                    <p>
                        The Down about Down Podcast covered the event. You can listen to their latest episode featuring the Corner Stone Fest here:
                        <a href="https://open.spotify.com/episode/7o3mRZwydm0GxI42QgwnS6" target="_blank" rel="noopener noreferrer">
                            Listen to the podcast
                        </a>
                    </p>

                    <h2>Gallery</h2>
                    <div className="image-gallery">
                        {images.map((image, index) => (
                            <img 
                                key={index}
                                src={require(`../photos/${image}`)}
                                alt={`Cornerstone Festival ${index + 1}`}
                                className="gallery-image"
                            />
                        ))}
                    </div>

                    <h2>Looking Forward</h2>
                    <p>
                        The Corner Stone Festival aims to grow stronger in the coming years, continuing to support LCCL Charity 
                        and provide a platform for local musicians. Stay tuned for announcements about next year's event!
                    </p>

                    <h2>More Information</h2>
                    <p>
                        For more details on events at Sunflower Cottage, visit their 
                        <a href="https://www.facebook.com/profile.php?id=100085277680028" target="_blank" rel="noopener noreferrer">
                            Facebook page
                        </a>.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default CornerstoneReview;