// src/components/NavBar.js
import React, { useState } from 'react';
import './navBar.css';
import logo from './logo.PNG'; // Ensure the correct import path and case
import { Link } from 'react-router-dom';

const NavBar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <nav className="navbar">
            <div className="navbar-logo">
                <Link to="/"><img src={logo} alt="Black Dog Moon Logo" /></Link>
            </div>
            <div className="navbar-toggle" onClick={toggleMenu}>
                &#9776;
            </div>
            <ul className={`navbar-links ${isOpen ? 'open' : ''}`}>
                <li><Link to="/" onClick={toggleMenu}>Home</Link></li>
                <li><Link to="/about" onClick={toggleMenu}>About</Link></li>
                <li><Link to="/merchandise" onClick={toggleMenu}>Merchandise</Link></li>
                <li><Link to="/gallery" onClick={toggleMenu}>Gallery</Link></li>
                <li><Link to="/cornerstone-review" onClick={toggleMenu}>Cornerstone Review 2024</Link></li>
                <li><Link to="/contact" onClick={toggleMenu}>Contact</Link></li>
            </ul>
        </nav>
    );
};

export default NavBar;
