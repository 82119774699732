import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './gallery.css';

const Gallery = () => {
    const [images, setImages] = useState([]);

    useEffect(() => {
        const importAll = (r) => {
            return r.keys().map((fileName) => ({
                src: r(fileName),
                alt: fileName.replace(/^\.\//, '').replace(/\.(png|jpe?g|svg)$/i, '')
            }));
        };

        try {
            const context = require.context('../photos', false, /\.(png|jpe?g|svg)$/i);
            const importedImages = importAll(context);
            //console.log('Imported images:', importedImages);
            setImages(importedImages);
        } catch (error) {
            console.error('Error importing images:', error);
        }

        console.log('Gallery component mounted');
    }, []);

    return (
        <div className="gallery-container">
            <h1>Gallery</h1>
            {images.length > 0 ? (
                <Carousel
                    showArrows={true}
                    showThumbs={false}
                    infiniteLoop={true}
                    autoPlay={true}
                    interval={5000}
                    stopOnHover={true}
                >
                    {images.map((image, index) => (
                        <div key={index}>
                            <img 
                                src={image.src} 
                                alt={image.alt} 
                                onError={(e) => {
                                    console.error(`Error loading image ${image.alt}:`, e);
                                    e.target.src = 'https://via.placeholder.com/400x300?text=Image+Not+Found';
                                }}
                                onLoad={() => console.log(`Image loaded successfully: ${image.src}`)}
                            />
                            <p className="legend">{image.alt}</p>
                        </div>
                    ))}
                </Carousel>
            ) : (
                <p>No images found in the gallery. Total images: {images.length}</p>
            )}
        </div>
    );
};

export default Gallery;
