// src/components/CornerstoneFestival.js
import React from 'react';
import './cornerstone.css';
import festivalPoster from './cornerstone.jpg';

const CornerstoneFestival = () => {
    return (
        <div className="cornerstone-container">
            <h1>Cornerstone Festival</h1>
            
            <div className="festival-content">
                <div className="poster-container">
                    <img src={festivalPoster} alt="Cornerstone Festival Poster" className="festival-poster" />
                </div>
                
                <div className="festival-info">
                    <h2>About the Festival</h2>
                    <p>
                        Cornerstone Festival is an annual charity event organized by Conal Montgommery. 
                        Each year, we bring together amazing artists and music lovers to support a great cause.
                    </p>
                    
                    <h2>This Year's Charity</h2>
                    <p>
                        This year, all proceeds from Cornerstone Festival will go to <a href="https://lifechangechangeslives.com/" target="_blank" rel="noopener noreferrer">LCCL</a>, 
                        an organization dedicated to helping people overcome their mental health issues, social isolation and addiction by offering a warm and welcoming environment for everyone of any age and any background..
                    </p>
                    
                    <h2>How You Can Help</h2>
                    <p>
                        By attending Cornerstone Festival, you're not just in for a great time – 
                        you're also supporting a worthy cause. Every ticket sold, every merchandise 
                        item purchased, and every donation made goes directly to our chosen charity.
                    </p>
                    
                    <h2>Festival Details</h2>
                    <ul>
                        <li><strong>Date:</strong> 10th August</li>
                        <li><strong>Location:</strong> The Sunflower Cottage - Annacloy</li>
                        <li><strong>Tickets:</strong> Contact Conal Montgommery</li>
                    </ul>

                    <h2>Contact Information</h2>
                    <p>For any inquiries about the Cornerstone Festival, please contact us:</p>
                    <ul>
                        <li><strong>Phone:</strong> <a href="tel:07990500891">07990500891</a></li>
                        <li><strong>Email:</strong> <a href="mailto:conaleaf@outlook.com">conaleaf@outlook.com</a></li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default CornerstoneFestival;